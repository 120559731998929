<template>
  <div>
    <!-- Status Update Modal -->
    <b-modal
      id="status-update-modal"
      size="lg"
      hide-footer
      no-close-on-backdrop
      @close="resetForm"
      @shown="form.priority = lead.priority"
      @ok="emitStatusUpdate"
    >
      <b-row>
        <b-col
          v-if="canChangeStatus"
          md="6"
        >
          <b-form-group :label="$t('Change Status To')">
            <b-form-select
              v-model="form.status"
              :options="possibleLeadStatus(lead.status)"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('Priority')">
            <b-form-select
              v-model="form.priority"
              :options="priorityOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <validation-observer ref="statusUpdateForm">
        <validation-provider
          #default="{errors}"
          name="description"
          rules="required"
        >
          <b-form-group :label="$t('Description')">
            <quill-editor
              ref="myQuillEditor"
              v-model="form.description"
              :options="editorOption"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
      <b-row>
        <b-col class="text-right py-2">
          <b-button
            variant="success"
            @click="emitStatusUpdate"
          >
            {{ $t('Update Status') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="lead.histories && lead.histories.data.length">
        <b-col md="12">
          <b-card
            v-for="history,i in lead.histories.data"
            :key="i"
            class="p-1"
            no-body
          >
            <b-row class="">
              <b-col
                md="1"
                class="p-0"
              >
                <b-avatar
                  :src="getAvatar(history.agent.avatar)"
                  size="60"
                  class="mb-1 ml-1"
                  :text="getInitials(history.agent.name)"
                />
              </b-col>
              <b-col
                md="11"
                class="pl-2 pt-0.5"
              >
                <b-badge
                  class="float-right"
                  pill
                  variant="success"
                >
                  {{ getStatus(history.newStatus) }}
                </b-badge>

                <feather-icon
                  v-if="history.oldStatus!=history.newStatus"
                  class="float-right text-primary"
                  icon="ArrowRightIcon"
                  size="18"
                />
                <b-badge
                  v-if="history.oldStatus!=history.newStatus"
                  class="float-right"
                  pill
                  variant="primary"
                >
                  {{ getStatus(history.oldStatus) }}
                </b-badge>
                <h5 class>
                  {{ history.agent.name }}
                </h5>
                <p v-html="history.description" />
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="create-company"
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <company-form
        :finalizing="true"
        @create-company="createCompany"
      />
    </b-modal>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  BFormGroup, BModal, BFormSelect, BRow, BCol, BCard, BAvatar, BBadge, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import { getStatus } from '@/const/common'
import useApollo from '@/plugins/graphql/useApollo'
import CompanyForm from '../Settings/company/main/CompanyForm.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BButton,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    CompanyForm,
  },

  props: {
    lead: {
      type: [Array, Object],
      default: () => {},
    },
    canCloseLeads: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      editorOption: {
        placeholder: 'Please write Description',
      },
      priorityOptions: [
        { text: 'Default', value: 'DEFAULT' },
        { text: 'High', value: 'HIGH' },
        { text: 'Urgent', value: 'URGENT' },
      ],
      form: {
        status: null,
        description: '',
        priority: 'DEFAULT',
      },
    }
  },
  computed: {
    canChangeStatus() {
      if (this.lead.status === 'DECLIEND' || this.lead.status === 'NOANSWER') return 0
      return (this.lead.status === 'SIGNED' || this.lead.status === 'SIGNED') && !this.canCloseLeads ? 0 : 1
    },
  },
  methods: {
    emitStatusUpdate(event) {
      event.preventDefault()
      this.$refs.statusUpdateForm.validate().then(success => {
        if (success) {
          this.$bvModal.hide('status-update-modal')
          if (this.form.status === 'SIGNED') {
            this.$bvModal.show('create-company')
          } else {
            this.$emit('update-status', {
              uuid: this.lead.uuid,
              status: this.form.status,
              description: this.form.description,
              priority: this.form.priority,
            })
          }
        }
      })
    },
    createCompany(data) {
      const form = {
        ownerEmail: data.ownerEmail,
        projectUid: this.$store.state.project.selectedProject,
        leadUid: this.lead.uuid,
        input: {
          trackingType: data.trackingType,
          weekStartDay: data.weekStartDay,
          timezoneOffset: data.timezoneOffset,
          currency: '$',
        },
      }
      useApollo.company.finalizeLead(form).then(() => {
        this.$emit('update-status', { uuid: this.lead.uuid, status: this.form.status, description: this.form.description })
        this.$bvModal.hide('create-company')
      })
    },

    getStatus(status) {
      return getStatus(status)
    },
    resetForm() {
      this.form = {
        status: null,
        description: '',
        priority: 'DEFAULT',
      }
    },
    possibleLeadStatus(status) {
      switch (status) {
        case 'NEWLEAD':
          return [
            { text: 'Select A Status', value: null, disabled: true },
            { text: 'In Progress', value: 'INPROGRESS' },
          ]
        case 'INPROGRESS':
          return [
            { text: 'Select A Status', value: null, disabled: true },
            { text: 'In Progress', value: 'INPROGRESS' },
            { text: 'No Answer', value: 'NOANSWER' },
            { text: 'Waiting Closure', value: 'WAITINGCLOSURE' },
            ...(this.canCloseLeads ? [
              { text: 'Signed', value: 'SIGNED' },
              { text: 'Declined', value: 'DECLINED' },
            ] : []),
          ]
        case 'WAITINGCLOSURE':
          return [
            { text: 'Select A Status', value: null, disabled: true },
            { text: 'Signed', value: 'SIGNED' },
            { text: 'Declined', value: 'DECLINED' },
          ]
        case 'SIGNED':
          return [
            { text: 'Select A Status', value: null, disabled: true },
            { text: 'Declined', value: 'DECLINED' },
          ]
        default:
          return []
      }
    },
  },
}
</script>

<style lang="scss">
#status-update-modal {
  .modal-content {
    min-height: 400px;
  }
  .ql-editor {
    min-height: 200px;
  }
}
.dark-layout {
    .ql-snow {
      .ql-stroke {
        stroke: #a6a6a6;
      }
      .ql-fill {
        fill: #a6a6a6;
      }
      .ql-picker-label {
        color: #a6a6a6;
      }
    }
  }
</style>
