<template>
  <div>
    <b-modal
      id="composeEmailModal"
      :title="$t('Compose Email')"
      :ok-title="$t('Send')"
      :cancel-title="$t('Cancel')"
      size="xl"
      no-close-on-backdrop
      @hidden="clearForm"
      @close="clearForm"
      @ok="sendEmail"
    >
      <validation-observer ref="emailForm">
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="{errors}"
              name="subject"
              rules="required"
            >
              <b-form-group :label="$t('Subject')">
                <b-form-input
                  v-model="form.subject"
                  type="text"
                  :placeholder="$t('Subject')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('Send To')">
              <b-form-input
                v-model="data.email"
                type="email"
                placeholder="info@example.com"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <validation-provider
          #default="{errors}"
          name="content"
          rules="required"
        >
          <b-form-group :label="$t('Content')">
            <quill-editor
              ref="myQuillEditor"
              v-model="form.content"
              :options="editorOption"
              @change="onEditorChange($event)"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  BFormGroup, BFormInput, BModal, BRow, BCol,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    quillEditor,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => '',
    },
  },
  data() {
    return {
      editorOption: {
        placeholder: 'Compose your email',
      },
      form: {
        content: '',
        subject: '',
      },
    }
  },
  methods: {
    sendEmail(bvModal) {
      bvModal.preventDefault()
      this.$refs.emailForm.validate().then(success => {
        if (success) {
          useApollo.company.sendEmailForLead({
            input: {
              content: this.form.content,
              subject: this.form.subject,
            },
            projectUid: this.$store.state.project.selectedProject,
            leadUid: this.data.uuid,
            contact: this.data?.contactId,
          }).then(() => {
            this.showSuccessMessage({
              data: {
                message: `Mail sent to ${this.data.email}`,
              },
            })
          }).catch(err => {
            this.showErrorMessage(err)
          }).finally(() => {
            this.$bvModal.hide('composeEmailModal')
          })
        }
      })
    },
    onEditorChange({ quill, html, text }) {
      this.form.content = html
    },
    clearForm() {
      this.form = {
        content: '',
        subject: '',
      }
    },
  },
}
</script>

<style lang="scss">
#composeEmailModal,
#description-modal {
  .modal-content {
    min-height: 400px;
  }
  .ql-editor {
    min-height: 350px;
  }
}
.dark-layout {
    .ql-snow {
      .ql-stroke {
        stroke: #a6a6a6;
      }
      .ql-fill {
        fill: #a6a6a6;
      }
      .ql-picker-label {
        color: #a6a6a6;
      }
    }
  }
</style>
