<template>
  <b-modal
    id="addEditLeads"
    :title="$t('Add Contact Info')"
    :ok-title="form.uuid ? $t('Update') : $t('Submit')"
    :cancel-title="$t('Cancel')"
    size="lg"
    no-close-on-backdrop
    @ok="handleSubmit"
    @shown="setForm"
    @hidden="clearForm"
  >
    <validation-observer ref="leadsForm">
      <b-row>
        <!-- Company Info -->
        <b-col md="6">
          <h4 class="font-weight-bold mb-1">
            {{ $t('Company Info') }}:
          </h4>
          <!-- Company Name -->
          <validation-provider
            #default="{errors}"
            name="company name"
            rules="required"
          >
            <b-form-group :label="$t('Company Name')">
              <b-form-input
                v-model="form.name"
                type="text"
                :placeholder="$t('Company Name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Company Address -->
          <validation-provider
            #default="{errors}"
            name="company address"
            rules="required"
          >
            <b-form-group :label="$t('Company Address')">
              <b-form-input
                v-model="form.address"
                type="text"
                :placeholder="$t('Company Address')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Company Phone -->
          <validation-provider
            #default="{errors}"
            name="company phone"
            rules="required"
          >
            <b-form-group :label="$t('Company Phone')">
              <b-form-input
                v-model="form.phone"
                type="tel"
                :placeholder="$t('Company Phone')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Company Email -->
          <validation-provider
            #default="{errors}"
            name="company email"
            rules="required|email"
          >
            <b-form-group :label="$t('Company Email')">
              <b-form-input
                v-model="form.email"
                type="email"
                :placeholder="$t('Company Email')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Contact Persons -->
        <b-col md="6">
          <h4 class="font-weight-bold mb-1">
            {{ $t('Contact Persons') }}:
          </h4>
          <div
            v-for="contact, index in contacts"
            :key="index"
          >
            <p v-b-toggle="`contact-${index}`">
              {{ $t('Contact') }} {{ index + 1 }}
              <feather-icon
                :icon="visibleAccordion === `contact-${index}` ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              />
            </p>
            <!-- First Contact Person -->
            <b-collapse
              :id="`contact-${index}`"
              :visible="activeContact === `contact-${index}` ? true : false"
            >
              <!-- Contact Name -->
              <validation-provider
                #default="{errors}"
                name="name"
                :rules="index == 0 ? 'required' : ''"
              >
                <b-form-group :label="$t('Name')">
                  <b-form-input
                    v-model="contact.name"
                    type="text"
                    :placeholder="$t('Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Contact Address -->
              <validation-provider
                #default="{errors}"
                name="address"
                :rules="index == 0 ? 'required' : ''"
              >
                <b-form-group :label="$t('Address')">
                  <b-form-input
                    v-model="contact.address"
                    type="text"
                    :placeholder="$t('Address')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Contact Phone -->
              <validation-provider
                #default="{errors}"
                name="phone"
                :rules="index == 0 ? 'required' : ''"
              >
                <b-form-group :label="$t('Phone')">
                  <b-form-input
                    v-model="contact.phone"
                    type="tel"
                    :placeholder="$t('Phone')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Contact Email -->
              <validation-provider
                #default="{errors}"
                name="email"
                :rules="index == 0 ? 'required|email' : 'email'"
              >
                <b-form-group :label="$t('Email')">
                  <b-form-input
                    v-model="contact.email"
                    type="email"
                    :placeholder="$t('Email')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-collapse>
          </div>
          <b-button
            v-if="canAddContacts"
            class="mt-1"
            variant="outline-primary"
            @click="addContact"
          >
            <feather-icon
              icon="PlusIcon"
              color="#7367f0"
            />
            {{ $t('Add Contact') }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import {
  BFormGroup, BFormInput, BCollapse, VBToggle, BButton, BRow, BCol,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCollapse,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    lead: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      email,
      form: {},
      contacts: [
        {
          name: '', address: '', phone: '', email: '',
        },
      ],
      visibleAccordion: 'contact-0',
    }
  },
  computed: {
    activeContact() {
      return this.visibleAccordion
    },
    canAddContacts() {
      return this.contacts.length < 3
    },
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (isJustShown) this.visibleAccordion = collapseId
      if (this.visibleAccordion === collapseId) {
        if (!isJustShown) this.visibleAccordion = ''
      }
    })
  },
  methods: {
    handleSubmit(bvModal) {
      bvModal.preventDefault()
      this.$refs.leadsForm.validate().then(success => {
        if (success) {
          let api = ''
          const data = {
            input: this.form,
            contacts: this.contacts,
          }
          data.projectUid = this.$store.state.project.selectedProject

          if (this.form.uuid) {
            api = 'updateCompanyLead'
            data.leadUid = this.form.uuid
            delete data.input._showDetails
            delete data.input.uuid
          } else {
            api = 'createCompanyLead'
          }

          useApollo.company[api](data).then(response => {
            this.showSuccessMessage({
              data: {
                message: response.data[api].message,
              },
            })
            this.$emit('refetch-leads')
          }).catch(err => {
            this.showErrorMessage(err)
          }).finally(() => {
            this.$bvModal.hide('addEditLeads')
          })
        }
      })
    },
    addContact() {
      this.contacts.push({
        name: '', address: '', phone: '', email: '',
      })
      this.visibleAccordion = `contact-${this.contacts.length - 1}`
    },
    setForm() {
      if (this.lead) {
        const {
          contacts, status, __typename: typename, ...form
        } = this.lead
        this.contacts = contacts.map(({ __typename, ...contact }) => contact)
        this.form = form
      }
    },
    clearForm() {
      this.$emit('clear-selected')
      this.form = {}
      this.contacts = [{
        name: '', address: '', phone: '', email: '',
      }]
      this.visibleAccordion = 'contact-0'
    },
  },
}
</script>
